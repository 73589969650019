import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CompanyService } from '@qaroni-app/company/services/company.service';
import { Company } from '@qaroni-app/company/types/company';
import { QaroniBaseSubsDirective } from '@qaroni-core/directives/qaroni-base-subs/qaroni-base-subs.directive';
import { qaroniAnimations } from '@qaroni-shared/animations/qaroni-animations';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'qaroni-company-widget',
  templateUrl: './company-widget.component.html',
  styleUrls: ['./company-widget.component.scss'],
  animations: [qaroniAnimations.enterFadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyWidgetComponent
  extends QaroniBaseSubsDirective
  implements OnInit
{
  public company = signal<Company | null>(null);

  private company$: Observable<Company> = this.companyService
    .getCompany$()
    .pipe(shareReplay(1));

  private paramMap$: Observable<ParamMap> = this.route.paramMap.pipe(
    shareReplay(1)
  );

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.add(this.company$.subscribe(this.getCompany));
    this.subs.add(this.paramMap$.subscribe(this.getParamMap));
  }

  get goToUpdateCompany(): string[] {
    return [`/companies/${this.company().companyId}/update`];
  }

  private getCompany = (company: Company) => {
    if (company?.companyId) {
      this.company.set(company);
    }
  };

  private getParamMap = (paramMap: ParamMap): void => {
    if (paramMap.has('companyID')) {
      const companyID = parseInt(paramMap.get('companyID'));
      this.companyService.getCompany(companyID);
    }
  };
}
