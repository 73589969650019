import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { CompanyService } from '@qaroni-app/company/services/company.service';
import { Scoreboards } from '@qaroni-app/company/types/scoreboards';
import { EmployeeShiftStatusSelectorEnum } from '@qaroni-app/employee/types/employee-shift-status.enum';
import { EmployeeStatusEnum } from '@qaroni-app/employee/types/employee-status.enum';
import { QaroniBaseSubsDirective } from '@qaroni-core/directives/qaroni-base-subs/qaroni-base-subs.directive';
import { qaroniAnimations } from '@qaroni-shared/animations/qaroni-animations';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'qaroni-scoreboard-widget',
  templateUrl: './scoreboard-widget.component.html',
  styleUrls: ['./scoreboard-widget.component.scss'],
  animations: [qaroniAnimations.enterFadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreboardWidgetComponent
  extends QaroniBaseSubsDirective
  implements OnInit
{
  private companyID: number;

  private paramMap$: Observable<ParamMap> = this.route.paramMap.pipe(
    shareReplay(1)
  );

  public scoreboards$: Observable<Scoreboards> = this.companyService
    .getCompanyScoreboards$()
    .pipe(shareReplay(1));

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.add(this.paramMap$.subscribe(this.getParamMap));
  }

  get goToEmployees(): string[] {
    return this.companyID
      ? [`/companies/${this.companyID}/employees/list`]
      : null;
  }

  get employeesOnlineParam(): Params {
    return {
      status: EmployeeStatusEnum.ACTIVE,
      shiftStatus: EmployeeShiftStatusSelectorEnum.ONLINE,
    };
  }

  get employeesOfflineParam(): Params {
    return {
      status: EmployeeStatusEnum.ACTIVE,
      shiftStatus: EmployeeShiftStatusSelectorEnum.OFFLINE,
    };
  }

  private getParamMap = (paramMap: ParamMap): void => {
    if (paramMap.has('companyID')) {
      this.companyID = parseInt(paramMap.get('companyID'));
      this.companyService.getCompanyScoreboards(this.companyID);
    }
  };
}
