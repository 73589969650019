import { AbstractControl, Validators } from '@angular/forms';
import { QaroniBaseFormsDirective } from '@qaroni-core/directives/qaroni-base-forms/qaroni-base-forms.directive';
import { format, parseISO } from 'date-fns';

export class CheckWidgetForm extends QaroniBaseFormsDirective {
  private skeleton = {
    type: ['WEB', Validators.required],
    startDate: ['', Validators.required],
  };

  public form = this.fb.group(this.skeleton);

  get startDate(): AbstractControl {
    return this.form.get('startDate');
  }

  public prepateDataToSend(): void {
    const now = new Date().toISOString().slice(0, -1);
    const date = format(parseISO(now), 'yyyy-MM-dd HH:mm:ss');
    this.startDate.setValue(date);
  }
}
